




























































































import Vue from "vue";

import EncuestaService from "@/api/encuesta/EncuestaService";
export default Vue.extend({
  props: ["dialog", "dato"],
  data() {
    return {
      accion: "1",
      motivoRechazo: "",
      editedIndex: -1,
      editedItem: {
        id: 0,
        nombre: ""
      },
      accionOptions: [
        { text: "Aprobar", value: "1" },
        { text: "Rechazar", value: "2" }
      ]
    };
  },
  mounted() {
    this.editedItem = { ...this.dato };
    //this.loadPapeleta();
  },
  methods: {
    save() {
      EncuestaService.accionFormularioEvento(this.editedItem.id, {
        accion: this.accion,
        motivoRechazo: this.motivoRechazo
      })
        .then(() => {
          this.close();
          this.$emit("saved", "someValue");
          this.$swal({
            toast: true,
            position: "bottom-right",
            showConfirmButton: false,
            timer: 4000,
            icon: "success",
            title: "Acción",
            text: "Guardada exitosamente"
          });
        })
        .catch(() => {
          this.$swal({
            toast: true,
            position: "bottom-right",
            showConfirmButton: false,
            timer: 3000,
            icon: "error",
            title: "Su acción",
            text: "No se pudo guardar"
          });
        });
    },
    /*
    getPapeletaImageUrl(fileName: string) {
      return PedidoService.getPapeletaImageUrl(fileName);
    },    
    loadPapeleta() {
      PedidoService.getPapeleta(this.editedItem.id)
        .then(response => {
          if (response) {
            this.papeleta = response;
          }
        })
        .catch(() => {
          console.log("no se pudo obtener la papeleta");
        });
    },
    */
    getAdjuntoUrl(fileName: string) {
      return EncuestaService.getAdjuntoUrl(fileName);
    },
    dialogChange(value: boolean) {
      this.$emit("dialogChange", value);
    },
    close() {
      this.dialogChange(false);
    }
  },
  computed: {},
  watch: {}
});
