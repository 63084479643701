
















































import EncuestaAprobar from "@/components/admin/encuestas/EncuestaAprobar.vue";
import Vue from "vue";
/*
import PedidoService, {
  PedidoCreatedDto,
  PedidoDto
} from "@/api/pedido/PedidoService";
*/
import EncuestaService, {
  GetFormularioEventoDto
} from "@/api/encuesta/EncuestaService";

export default Vue.extend({
  components: {
    //PapeletaAprobar
    EncuestaAprobar
  },
  metaInfo: {
    title: "Encuestas Pendientes"
  },
  data() {
    return {
      dialog: false,
      dato: {
        id: 0
      } as GetFormularioEventoDto,
      headers: [
        { text: "#", value: "id" },
        { text: "Identificación", value: "encuestado.identificacion" },
        { text: "Nombre", value: "encuestado.nombre" },
        { text: "Apellido", value: "encuestado.apellido" },
        { text: "Encuesta", value: "encuesta.nombre" },
        //{ text: "Monto", value: "displayTotal" },
        //{ text: "Estado", value: "estadoDisplay" },
        { text: "Acción", value: "actions", sortable: false }
      ],
      datos: [] as GetFormularioEventoDto[]
    };
  },
  methods: {
    setDefaultItem() {
      const newDto = {
        id: 0
      };
      this.dato = newDto;
    },
    editItem(item: GetFormularioEventoDto) {
      this.dato = item;
      this.dialogChange(true);
    },
    newItem() {
      this.setDefaultItem();
      this.dialogChange(true);
    },
    dialogChange(value: boolean) {
      this.dialog = value;
    },
    getAll() {
      EncuestaService.getAllFormularioEvento()
        .then(response => {
          this.datos = response;
        })
        .catch(() => {
          this.$swal({
            toast: true,
            position: "center",
            showConfirmButton: false,
            timer: 2000,
            icon: "error",
            title: "Pendientes",
            text: "No se pudo obtener"
          });
        });
    }
  },
  mounted() {
    this.getAll();
  },
  watch: {
    dialog: function(newVal) {
      if (newVal === false) {
        this.setDefaultItem();
      }
    }
  }
});
